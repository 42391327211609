import { useRouter } from "next/router";
import { ShortcutUpdate } from "./ShortcutUpdate";
import { ShortcutsCreateNew } from "../ShortcutsCreateNew";
import { useState } from "react";

export interface ShortcutControlProps {
  onComplete: () => void;
}

export const ShortcutControl = ({ onComplete }: ShortcutControlProps) => {
  const { query } = useRouter();
  const shortcutInParams =
    ((query?.sid as string | undefined)?.length || 0) > 0;
  const shortcutId = shortcutInParams ? (query?.sid as string) : null;
  const [forceCreate, setForceCreate] = useState<boolean>(false);

  const handleCreateComplete = () => {
    onComplete();
    setForceCreate(false);
  };

  if (shortcutId && !forceCreate) {
    return (
      <ShortcutUpdate
        onComplete={onComplete}
        shortcutId={shortcutId}
        onShowNew={() => setForceCreate(true)}
      />
    );
  }

  return <ShortcutsCreateNew onCreated={handleCreateComplete} />;
};
