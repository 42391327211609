import { getFragmentData, graphql } from "admin/graphql/generated";
import { GetFragmentType, useGraphQLQuery } from "made-this-ui";

export const CaseDefinitionCaseFragment = graphql(/* GraphQL */ `
  fragment CaseDefinitionCaseFragment on CaseInterface {
    id
    name
    shortName
    type
    icon
    attention
    awaiting
    state
    stateText
    __typename
  }
`);
export type CaseDefinitionCaseFragmentType = GetFragmentType<
  typeof CaseDefinitionCaseFragment
>;

export const CaseDefinitionInfoFragment = graphql(/* GraphQL */ `
  fragment CaseDefinitionInfoFragment on CaseDefinition {
    name
    icon
    type
    __typename
  }
`);

export const HeaderActionsDataQuery = graphql(/* GraphQL */ `
  query HeaderActionsData {
    inbox: caseDefinitions {
      ...CaseDefinitionInfoFragment
      cases(where: { newForCurrentUser: true }) {
        records {
          ...CaseDefinitionCaseFragment
        }
        count
      }
    }
    flagged: caseDefinitions {
      ...CaseDefinitionInfoFragment
      cases(where: { flaggedForCurrentUser: true }) {
        records {
          ...CaseDefinitionCaseFragment
        }
        count
      }
    }
    messages: caseDefinitions {
      ...CaseDefinitionInfoFragment
      cases(where: { commentsForCurrentUser: true }) {
        records {
          ...CaseDefinitionCaseFragment
        }
        count
      }
    }
  }
`);

const useFooterActionsDataQuery = () => {
  return useGraphQLQuery(HeaderActionsDataQuery);
};

export const useFooterActionsData = () => {
  const { data, isLoading, error } = useFooterActionsDataQuery();

  const inboxItems = data?.inbox ?? [];
  const flaggedItems = data?.flagged ?? [];
  const messageItems = data?.messages ?? [];

  const inboxCases = inboxItems
    ?.filter((item) => item.cases.count > 0)
    .map((caseDef) =>
      getFragmentData(CaseDefinitionCaseFragment, caseDef.cases.records)
    )
    .flat();

  const flaggedCases = flaggedItems
    ?.filter((item) => item.cases.count > 0)
    .map((caseDef) =>
      getFragmentData(CaseDefinitionCaseFragment, caseDef.cases.records)
    )
    .flat();

  const messageCases = messageItems
    ?.filter((item) => item.cases.count > 0)
    .map((caseDef) =>
      getFragmentData(CaseDefinitionCaseFragment, caseDef.cases.records)
    )
    .flat();

  return {
    isLoading,
    inbox: {
      records: inboxCases,
    },
    flagged: {
      records: flaggedCases,
    },
    messages: {
      records: messageCases,
    },
  };
};

export type UseFooterActionsDataReturn = ReturnType<
  typeof useFooterActionsData
>;
