import { Box, BoxProps, Center, CircularProgress } from "@chakra-ui/react";
import { useState } from "react";

export function Iframe({ src, ...rest }: BoxProps & { src: string }) {
  const [loaded, setLoaded] = useState<null | string>(null);

  return (
    <>
      {loaded !== src && (
        <Center
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg="white"
          zIndex="1"
        >
          <CircularProgress isIndeterminate color="primary.400" />
        </Center>
      )}
      <Box
        as="iframe"
        borderWidth="0"
        mt="1px"
        flex="1"
        onLoad={() => setLoaded(src)}
        src={src}
        {...rest}
      />
    </>
  );
}
