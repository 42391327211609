import { CurrentUserFragmentType } from "admin/lib/useCurrentUser";
import { ReactNode } from "react";
import { Navigation } from "./navigation/Navigation";
import { App, AppBody } from "made-this-ui";

interface LayoutAdminProps {
  children: ReactNode;
  user: CurrentUserFragmentType;
}

export const LayoutAdmin = ({ children, user }: LayoutAdminProps) => {
  return (
    <App colorScheme="primary">
      <Navigation user={user} />
      <AppBody>{children}</AppBody>
    </App>
  );
};
