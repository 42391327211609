import { ShortcutsListShortcut } from "./useShortcutsList";
import {
  Box,
  HStack,
  Icon,
  IconButton,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { useAdminTranslation } from "admin/lib/useAdminTranslation";

export interface ListItemShortcutProps {
  item: ShortcutsListShortcut;
  onItemClick?: (item: ShortcutsListShortcut) => void;
  onItemRemoveClick?: (item: ShortcutsListShortcut) => void;
  isActive?: boolean;
}

export const ListItemShortcut = ({
  onItemClick,
  onItemRemoveClick,
  item,
  isActive,
}: ListItemShortcutProps) => {
  const { name, type } = item;
  const {
    icons: { IconMoreHorizontal, IconRemove },
    colors: { danger },
  } = useTheme();
  const { model, t } = useAdminTranslation();

  const handleItemCLicked = () => {
    onItemClick?.(item);
  };

  return (
    <ListItem
      id={`shortcut-item-${item.id}`}
      as={HStack}
      px={3}
      py={2}
      cursor={"pointer"}
      borderRadius="sm"
      _hover={{
        bg: "primaryAlpha.200",
      }}
      bg={isActive ? "primaryAlpha.200" : "transparent"}
      role={"group"}
      onClick={handleItemCLicked}
    >
      <Stack flexGrow={1} spacing={"2px"}>
        <HStack>
          <Text textStyle={"body-md"}>{name}</Text>
        </HStack>
        <Text textStyle={"body-xs"} color={"gray.400"}>
          {model(type.slice(0), { count: 2 })}
        </Text>
      </Stack>
      <Box onClick={(e) => e.stopPropagation()}>
        <Menu>
          <MenuButton
            as={IconButton}
            variant={"unstyled"}
            icon={<IconMoreHorizontal />}
          />
          <MenuList onClick={(e) => e.stopPropagation()}>
            <MenuItem
              color={"danger"}
              as={HStack}
              spacing={2}
              w={"177px"}
              onClick={() => onItemRemoveClick?.(item)}
            >
              <Icon as={IconRemove} color={danger[600]} />
              <Text color={danger[600]}>
                {t("texts.shortcuts.remove_title")}
              </Text>
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </ListItem>
  );
};
