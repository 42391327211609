const Modal = {
  baseStyle: {
    body: {
      px: [3, 6],
      py: [3, 5],
    },
    header: {
      px: [3, 6],
    },
    footer: {
      px: [3, 6],
    },
    dialog: {
      mx: 2,
    },
  },
};

export default Modal;
