import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { createContext, ReactNode, useContext, useMemo, useState } from "react";
import { Iframe } from "./Iframe";

export type IframeModalManagerContextValue = {
  open: (url: string) => void;
  close: () => void;
};

export const IframeModalManagerContext =
  createContext<IframeModalManagerContextValue>(null!);

export const useIframeModalManager = () => {
  return useContext(IframeModalManagerContext);
};

export const IframeModalManagerProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const [url, setUrl] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const value = useMemo(() => {
    return {
      open: (url: string) => {
        setUrl(url);
      },
      close: () => {
        queryClient.invalidateQueries();
        setUrl(null);
      },
    };
  }, [queryClient]);

  return (
    <IframeModalManagerContext.Provider value={value}>
      {url ? (
        <IframeModal
          url={url}
          onClose={() => {
            queryClient.invalidateQueries();
            setUrl(null);
          }}
        />
      ) : null}
      {children}
    </IframeModalManagerContext.Provider>
  );
};

const IframeModal = ({
  url,
  onClose,
}: {
  url: string;
  onClose: () => void;
}) => {
  return (
    <Modal isOpen={true} onClose={onClose} size="iframe">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <Iframe src={url} mt="0" borderRadius="md" />
      </ModalContent>
    </Modal>
  );
};
