import { graphql } from "admin/graphql/generated";
import { useGraphQLMutation } from "made-this-ui";

const ShortcutUpdateMutation = graphql(/* GraphQL */ `
  mutation ShortcutUpdate(
    $shortcutId: ID!
    $attributes: ShortcutAttributesInput!
  ) {
    shortcutUpdate(shortcutId: $shortcutId, attributes: $attributes) {
      errors
    }
  }
`);

export const useShortcutUpdateMutation = () => {
  return useGraphQLMutation(ShortcutUpdateMutation);
};
