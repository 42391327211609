import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";

interface ShortcutsModalProps {
  children: React.ReactNode;
  onClose: () => void;
}
export const ShortcutsModal = ({ children, onClose }: ShortcutsModalProps) => {
  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay />
      <ModalContent overflow="hidden" maxH={"80%"} padding={6}>
        {children}
      </ModalContent>
    </Modal>
  );
};
