import { ShortcutsListShortcut } from "./useShortcutsList";
import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { ListShortcuts } from "./ListShortcuts";
import { useAdminTranslation } from "admin/lib/useAdminTranslation";
import { useEffect, useMemo, useRef, useState } from "react";
import { useKeyPress } from "admin/lib/useKeyPress";
import { useRouter } from "next/router";

export interface SearchableShortcutsProps {
  shortcuts: ShortcutsListShortcut[];
  search: string;
  onSearchChange: (value: string) => void;
  onItemClick?: (item: ShortcutsListShortcut) => void;
  onItemRemoveClick?: (item: ShortcutsListShortcut) => void;
  onClose?: () => void;
}

export const SearchableShortcuts = ({
  shortcuts,
  search,
  onSearchChange,
  onItemClick,
  onItemRemoveClick,
  onClose,
}: SearchableShortcutsProps) => {
  const {
    icons: { IconSearch },
  } = useTheme();
  const router = useRouter();
  const { t } = useAdminTranslation();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const itemIds = useMemo(() => shortcuts.map((item) => item.id), [shortcuts]);
  const [activeItemId, setActiveItemId] = useState<string | null>(
    router.query.sid
      ? (router.query.sid as string)
      : itemIds.length > 0
      ? itemIds[0]
      : null
  );

  const handleItemClicked = (item: ShortcutsListShortcut) => {
    onItemClick?.(item);
    setActiveItemId(item.id);
    onClose?.();
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useKeyPress("ArrowUp", () => {
    const index = itemIds.indexOf(activeItemId!);
    if (index > 0) {
      setActiveItemId(itemIds[index - 1]);
    }
  });

  useKeyPress("ArrowDown", () => {
    const index = itemIds.indexOf(activeItemId!);
    if (index < itemIds.length - 1) {
      setActiveItemId(itemIds[index + 1]);
    }
  });

  useKeyPress("Enter", () => {
    const item = shortcuts.find((item) => item.id === activeItemId);
    if (item) {
      onItemClick?.(item);
      onClose?.();
    }
  });

  useEffect(() => {
    const querySelector = `#shortcut-item-${activeItemId}`;
    const element = document.querySelector(querySelector);
    if (!element) {
      return;
    }

    element.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  }, [activeItemId]);

  return (
    <Stack spacing={3}>
      <InputGroup>
        <InputLeftElement>
          <IconSearch w={"20px"} h={"20px"} />
        </InputLeftElement>
        <Input
          ref={inputRef}
          value={search}
          autoComplete={"off"}
          onChange={(e) => onSearchChange(e.target.value)}
        />
      </InputGroup>
      {shortcuts.length > 0 ? (
        <ListShortcuts
          items={shortcuts}
          onItemClick={handleItemClicked}
          onItemRemoveClick={onItemRemoveClick}
          activeItemId={activeItemId}
        />
      ) : (
        <Flex justifyContent={"center"} alignItems={"center"} minH={"240px"}>
          <Text textStyle={"body-lg"}>{t("texts.shortcuts.search_empty")}</Text>
        </Flex>
      )}
    </Stack>
  );
};
