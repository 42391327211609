const Heading = {
  baseStyle: {
    fontWeight: "400",
    letterSpacing: "0.01em",
    color: "blueGray.900",
    fontFamily: "heading",
  },
  sizes: {
    xl: {
      fontSize: ["4xl", "4xl", "5xl"],
      lineHeight: ["1.16", "1.16", "1.1"],
      fontWeight: "bold",
    },
    lg: {
      fontSize: ["3xl", "3xl", "4xl"],
      lineHeight: ["1.28", "1.28", "1.33"],
      fontWeight: "500",
    },
    md: {
      fontSize: ["2xl", "2xl", "3xl"],
      lineHeight: ["1.3", "1.3", "1.28"],
      fontWeight: "500",
    },
    sm: {
      fontSize: ["xl", "xl", "2xl"],
      lineHeight: "1.3",
      fontWeight: "500",
    },
  },
  defaultProps: {
    size: "md",
  },
};

export default Heading;
