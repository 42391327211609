import { useRouter } from "next/router";
import { fromURLToState } from "made-this-ui";

export enum ShortcutPathIdentifier {
  Cases = "case",
  Tenancy = "tenancy",
  Apartments = "apartment",
  Properties = "property",
  None = "@none",
}

type ShortcutPathType = string;

const pathMap: Record<ShortcutPathIdentifier, ShortcutPathType> = {
  case: "/next/admin/cases",
  tenancy: "/next/admin/tenancies",
  apartment: "/next/admin/apartments",
  property: "/next/admin/properties",
  "@none": "",
};

const getPathIdentifierFromPath = (path: string): ShortcutPathIdentifier => {
  for (const [identifier, mapPath] of Object.entries(pathMap)) {
    if (path.startsWith(mapPath)) {
      return identifier as ShortcutPathIdentifier;
    }
  }
  return ShortcutPathIdentifier.None;
};

export const getPathFromPathIdentifier = (
  pathIdentifier: ShortcutPathIdentifier
): ShortcutPathType => {
  return pathMap[pathIdentifier];
};

export const useShortcutPathMap = () => {
  const { pathname, query } = useRouter();
  const pathIdentifier = getPathIdentifierFromPath(pathname);

  const getState = () => {
    if (!Object.hasOwn(query, "s0")) {
      return null;
    }

    return fromURLToState(query.s0 as string);
  };

  return {
    pathIdentifier,
    getState,
  };
};
