import {
  HStack,
  Kbd as ChakraKbd,
  KbdProps,
  Text,
  TextProps,
} from "@chakra-ui/react";

export const Kbd = ({
  label,
  textProps,
  ...props
}: { label?: string; textProps?: TextProps } & KbdProps) => {
  return (
    <HStack spacing={1}>
      <ChakraKbd {...props} />
      {label && (
        <Text color="gray.400" {...textProps}>
          {label}
        </Text>
      )}
    </HStack>
  );
};
