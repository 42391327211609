import { ComponentType, createContext, ReactNode, useContext } from "react";
import {
  useFooterActionsData,
  UseFooterActionsDataReturn,
} from "./useFooterActionsData";

export const FooterActionsDataContext =
  createContext<UseFooterActionsDataReturn>(null!);

export const useHeaderActionsContext = () => {
  return useContext(FooterActionsDataContext);
};

export type FooterActionsDataProviderProps = {
  children: ReactNode;
};

export const FooterActionsDataProvider = ({
  children,
}: FooterActionsDataProviderProps) => {
  const actionsData = useFooterActionsData();

  return (
    <FooterActionsDataContext.Provider value={actionsData}>
      {children}
    </FooterActionsDataContext.Provider>
  );
};

type ComponentWithHeaderActionsDataProps = {
  actionData: UseFooterActionsDataReturn;
};

export const withFooterActionsDataProvider = <P extends {}>(
  Component: ComponentType<P & ComponentWithHeaderActionsDataProps>
) => {
  const ExtendedComponent = (props: P) => {
    return (
      <FooterActionsDataProvider>
        <FooterActionsDataContext.Consumer>
          {(actionData) => <Component {...props} actionData={actionData} />}
        </FooterActionsDataContext.Consumer>
      </FooterActionsDataProvider>
    );
  };
  ExtendedComponent.displayName = `withHeaderActionsDataProvider(${Component.displayName})`;
  return ExtendedComponent;
};
