import {
  Avatar,
  Box,
  ComponentWithAs,
  Flex,
  FlexProps,
  IconProps,
  useTheme,
} from "@chakra-ui/react";
import { useAdminTranslation } from "admin/lib/useAdminTranslation";
import { CurrentUserFragmentType } from "admin/lib/useCurrentUser";
import {
  AppNavigation,
  AppNavigationBody,
  AppNavigationFooter,
  AppNavigationHeader,
  AppNavigationItem,
  AppNavigationSubItem,
  useAppContext,
} from "made-this-ui";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { FooterActions } from "./FooterActions";
import { GlobalSearch } from "./GlobalSearch";
import { Shortcuts } from "./Shortcuts";

const getSidebarMenu = (icons: {
  [key: string]: ComponentWithAs<"svg", IconProps>;
}) => [
  {
    id: 0,
    href: "/next/admin/cases",
    title: "case",
    icon: icons.IconCases,
  },
  {
    id: 1,
    href: "/next/admin/tenancies",
    title: "tenancy",
    icon: icons.IconTenant,
  },
  {
    id: 2,
    href: "/next/admin/apartments",
    title: "apartment",
    icon: icons.IconApartment,
  },
  {
    id: 3,
    href: "/next/admin/properties",
    title: "property",
    icon: icons.IconProperty,
  },
];

const moreMenu = [
  {
    id: 0,
    href: "/admin/cases/my",
    title: "rails:cases.shared.calendar",
  },
  {
    id: 1,
    href: "/admin/interested",
    model: "interest_list_entry",
  },
  {
    id: 2,
    href: "/admin/timelogs",
    model: "timelog",
  },
  {
    id: 3,
    href: "/admin/messages",
    model: "message",
  },
  {
    id: 4,
    href: "/admin/questions",
    model: "question",
  },
  {
    id: 5,
    href: "/admin/documents",
    model: "document",
  },
  {
    id: 6,
    href: "/admin/users",
    model: "user",
  },
  {
    id: 7,
    href: "/admin/reports/leased",
    title: "rails:reports.title.other",
  },
  {
    id: 8,
    href: "/admin/accounting",
    title: "rails:accounting.title",
  },
  {
    id: 9,
    href: "/admin/configuration/case_message_rules",
    title: "rails:application.configuration",
  },
];

interface NavigationProps extends FlexProps {
  user: CurrentUserFragmentType;
}

export const Navigation = ({ user }: NavigationProps) => {
  const {
    icons: {
      IconCases,
      IconTenant,
      IconApartment,
      IconProperty,
      IconLogoFull,
      IconMoreHorizontal,
    },
  } = useTheme();

  const { isCollapsed } = useAppContext();

  const { t, model } = useAdminTranslation();

  const router = useRouter();
  const isPath = (path: string) => router.pathname.startsWith(path);

  const sidebarMenu = useMemo(() => {
    return getSidebarMenu({
      IconCases,
      IconTenant,
      IconApartment,
      IconProperty,
    });
  }, [IconProperty, IconCases, IconTenant, IconApartment]);

  return (
    <AppNavigation backgroundColor={"primary.500"}>
      <AppNavigationHeader
        title={user.name}
        avatar={
          <Avatar
            name={user.name}
            backgroundColor="gray.800"
            color="white"
            w={9}
            h={9}
          />
        }
      >
        <Flex
          px={isCollapsed ? 0 : 3}
          pb={3}
          flexDirection={isCollapsed ? "column" : "row"}
          justifyContent="space-between"
          alignItems={"center"}
          borderBottomWidth={1}
          borderBottomColor={"primary.600"}
        >
          <FooterActions />
          <GlobalSearch />
          <Shortcuts />
        </Flex>
      </AppNavigationHeader>
      <AppNavigationBody>
        <Box h="2" />

        {sidebarMenu.map(({ id, href, title, icon }) => {
          return (
            <AppNavigationItem
              key={id}
              label={model(title, { count: 2 })}
              isActive={isPath(href)}
              href={href}
              icon={icon}
            />
          );
        })}

        <AppNavigationItem label={t("concepts.more")} icon={IconMoreHorizontal}>
          {moreMenu.map(({ id, href, ...item }) => (
            <AppNavigationSubItem
              key={id}
              onClick={() => {
                window.location.href = href;
              }}
            >
              {item.model
                ? model(item.model, { count: 2 })
                : item.title
                ? t(item.title)
                : ""}
            </AppNavigationSubItem>
          ))}
        </AppNavigationItem>
      </AppNavigationBody>
      <AppNavigationFooter
        logo={<IconLogoFull color="gray.800" h={30} w={"auto"} />}
      />
    </AppNavigation>
  );
};
