import React from "react";
import {
  Box,
  Center,
  ComponentWithAs,
  IconButton,
  IconButtonProps,
  IconProps,
} from "@chakra-ui/react";
import { MouseEventHandler } from "react";

export interface ActionButtonProps {
  notificationCount?: number;
  btnRef?: any;
  icon: ComponentWithAs<"svg", IconProps>;
}

export const ActionButton = ({
  notificationCount,
  btnRef,
  icon: Icon,
  ...props
}: ActionButtonProps & Omit<IconButtonProps, "icon" | "aria-label">) => (
  <Box pos="relative" ref={btnRef}>
    <IconButton
      aria-label="action"
      variant="ghost"
      icon={<Icon fontSize={28} />}
      disabled={!notificationCount}
      {...props}
    />
    {!!notificationCount && (
      <Center
        rounded="sm"
        color="white"
        bg="warning.300"
        fontSize="10.5px"
        border="1px solid"
        borderColor="primary.50"
        cursor="pointer"
        pos="absolute"
        bottom={0}
        right={0}
        w="18px"
        h="18px"
        onClick={props.onClick as MouseEventHandler<any>}
      >
        {notificationCount}
      </Center>
    )}
  </Box>
);
