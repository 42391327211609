const textStyles = {
  buttonSolid: {
    fontFamily: "body",
    fontSize: "sm",
    fontWeight: "600",
    lineHeight: "1.375",
  },
  buttonLink: {
    fontFamily: "body",
    fontSize: "md",
    fontWeight: "500",
  },
  subtitleNormal: {
    fontFamily: "body",
    fontSize: "md",
    fontWeight: "400",
  },
  subtitleMedium: {
    fontFamily: "body",
    fontSize: "md",
    fontWeight: "500",
  },
  subtitleBold: {
    fontFamily: "body",
    fontSize: "md",
    fontWeight: "600",
  },
  bodyNormal: {
    fontFamily: "body",
    fontSize: "sm",
    fontWeight: "400",
    lineHeight: "1.35",
    color: "black",
  },
  bodyMedium: {
    fontFamily: "body",
    fontSize: "sm",
    fontWeight: "500",
    color: "black",
  },
  bodyBold: {
    fontFamily: "body",
    fontSize: "sm",
    fontWeight: "600",
    color: "black",
  },
  captionNormal: {
    fontFamily: "body",
    fontSize: "xs",
    fontWeight: "400",
  },
  titleMd: {
    fontFamily: "heading",
    fontSize: ["2xl", "2xl", "3xl"],
    lineHeight: ["1.3", "1.3", "1.28"],
    fontWeight: "400",
  },
  titleSm: {
    fontFamily: "heading",
    fontSize: ["xl", "xl", "2xl"],
    lineHeight: "1.3",
    fontWeight: "400",
  },
};

export default textStyles;
