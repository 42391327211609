import { HStack, StackProps, useTheme } from "@chakra-ui/react";
import { Kbd } from "admin/components/common/Kbd";
import { useAdminTranslation } from "admin/lib/useAdminTranslation";

export interface KbdNavigationProps extends StackProps {}

export const KbdNavigation = (props: KbdNavigationProps) => {
  const { t } = useAdminTranslation();
  const {
    icons: { IconArrowUp, IconArrowDown, IconEnterKey },
  } = useTheme();

  return (
    <HStack spacing={1} justify="flex-end" {...props}>
      <Kbd label={t("concepts.navigate")}>
        <IconArrowUp fontSize="xs" />
        <IconArrowDown fontSize="xs" />
      </Kbd>
      <Kbd label={t("concepts.open")}>
        <IconEnterKey fontSize="xs" />
      </Kbd>
      <Kbd label={t("concepts.close")}>Esc</Kbd>
    </HStack>
  );
};
