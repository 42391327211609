import { Box, useTheme } from "@chakra-ui/react";
import {
  FooterPopoverNotificationItem,
  FooterPopoverTitle,
  CaseDefinitionCaseFragmentType,
} from ".";
import { IconCase } from "admin/components/common/IconCase";
import { useNavigationBetweenPopoverItems } from "admin/lib/useNavigationBetweenPopoverItems";
import { useAdminTranslation } from "admin/lib/useAdminTranslation";

interface FooterNavigationMessagesProps {
  records: CaseDefinitionCaseFragmentType[];
}

export const FooterPopoverMessages = ({
  records,
}: FooterNavigationMessagesProps) => {
  const {
    icons: { IconMessages },
  } = useTheme();
  const { activeIdx } = useNavigationBetweenPopoverItems(records);
  const { t } = useAdminTranslation();

  return (
    <Box>
      <FooterPopoverTitle icon={IconMessages} title={t("concepts.comments")} />

      <Box mt={4}>
        {records.map((item, idx) => (
          <FooterPopoverNotificationItem
            key={item.id}
            icon={<IconCase case={item} />}
            title={item.shortName}
            description={item.name}
            isActive={activeIdx === idx}
            href={""}
          />
        ))}
      </Box>
    </Box>
  );
};
