import { useState } from "react";
import { useKeyPress } from "./useKeyPress";

export const useNavigationBetweenPopoverItems = (data: any[]) => {
  const [activeIdx, setActiveIdx] = useState<number | null>(null);

  const getActiveIdx = (direction: "up" | "down") => {
    const selectedIdx = activeIdx ?? 0;
    const canIncreaseIdx = data.length - 1 > selectedIdx;
    const nextIdx = canIncreaseIdx ? selectedIdx + 1 : selectedIdx;
    const prevIdx = selectedIdx ? selectedIdx - 1 : 0;

    return direction === "up" ? prevIdx : nextIdx;
  };

  const handleIdxChange = (direction: "up" | "down") => {
    const idx = getActiveIdx(direction);
    setActiveIdx(idx);
  };

  useKeyPress("ArrowUp", () => handleIdxChange("up"));
  useKeyPress("ArrowDown", () => handleIdxChange("down"));

  return {
    activeIdx,
  };
};
