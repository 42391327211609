const fonts = {
  body: 'inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
  heading:
    'geomanist, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
};

const fontSizes = {
  xs: "0.75rem",
  sm: "0.875rem",
  md: "1rem",
  xl: "1.125rem",
  "2xl": "1.25rem",
  "3xl": "1.75rem",
  "4xl": "2.25rem",
  "5xl": "3.375rem",
};

const fontSources = {
  inter: "https://rsms.me/inter/inter.css",
  geomanist: "https://ui.madethis.dev/fonts/geomanist/geomanist.css",
};

export { fonts, fontSizes, fontSources };
