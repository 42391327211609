const solidButton = (c) => ({
  textStyle: "buttonLink",
  bg: `${c}.700`,
  color: "white",
  _hover: {
    bg: `${c}.800`,
    _disabled: {
      bg: `${c}.600`,
    },
  },
  _active: {
    bg: `${c}.900`,
  },
  _focus: {
    boxShadow: "none",
  },
});

const linkButton = (c) => ({
  textStyle: "buttonLink",
  height: "auto",
  padding: 0,
  lineHeight: "normal",
  verticalAlign: "baseline",
  color: `${c}.600`,
  _hover: {
    textDecoration: "underline",
    _disabled: {
      textDecoration: "none",
    },
  },
  _active: {
    color: `${c}.800`,
  },
});

const outlineButton = (c) => ({
  textStyle: "buttonLink",
  bg: `transparent`,
  color: `${c}.700`,
  borderRadius: "6px",
  border: "1px solid",
  borderColor: `${c}.700`,
  _active: {
    color: "white",
  },
  _focus: {
    boxShadow: "none",
  },
});

const loginButton = (bgColor, textColor) => ({
  display: "flex",
  justify: "center",
  textStyle: "buttonLink",
  color: textColor,
  bg: bgColor,
  p: "11px 16px",
});

const Button = {
  variants: {
    ghost: {
      textStyle: "buttonSolid",
    },
    solidCyan: solidButton("cyan"),
    solidBlue: solidButton("lightBlue"),
    solidRed: solidButton("red"),
    solidGreen: solidButton("green"),
    linkBlue: linkButton("lightBlue"),
    linkAmber: linkButton("amber"),
    linkRed: linkButton("red"),
    linkGreen: linkButton("green"),
    linkGray: linkButton("blueGray"),
    linkWhite: { ...linkButton("white"), textDecoration: "underline" },
    outlineCyan: {
      ...outlineButton("cyan"),
      _hover: {
        bg: "rgba(14, 116, 144, 0.05)",
      },
    },
    loginWhite: loginButton("white", "cyan.700"),
  },
  sizes: {
    lg: {
      h: "54px",
      px: 12,
      py: 4,
    },
  },
};

export default Button;
