import { extendTheme } from "@chakra-ui/react";
import { fonts, fontSizes, fontSources } from "./fonts";
import colors from "./colors";
import styles from "./styles";
import shadows from "./shadows";
import textStyles from "./textStyles";
import Modal from "./components/modal";
import layerStyles from "./layerStyles";
import Button from "./components/button";
import Divider from "./components/divider";
import Heading from "./components/heading";
import Checkbox from "./components/checkBox";
import FormLabel from "./components/formLabel";

const ejsingholmTheme = extendTheme({
  fontSources,
  fonts,
  styles,
  colors,
  shadows,
  fontSizes,
  textStyles,
  layerStyles,
  components: {
    FormLabel,
    Checkbox,
    Divider,
    Heading,
    Button,
    Modal,
  },
});

export default ejsingholmTheme;
