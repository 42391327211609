import { getFragmentData, graphql } from "admin/graphql/generated";
import { GetFragmentType, useGraphQLQuery } from "made-this-ui";

export const CurrentUser = graphql(/* GraphQL */ `
  query CurrentUser {
    currentUser {
      ...CurrentUserFragment
    }
  }
`);
export const CurrentUserFragment = graphql(/* GraphQL */ `
  fragment CurrentUserFragment on User {
    id
    name
    email
    isSystemAdministrator
    isAdministrator
    __typename
  }
`);

export type CurrentUserFragmentType = GetFragmentType<
  typeof CurrentUserFragment
>;

export const useCurrentUserQuery = () => useGraphQLQuery(CurrentUser);

export const useCurrentUser = (): CurrentUserFragmentType => {
  const { data } = useCurrentUserQuery();
  return getFragmentData(CurrentUserFragment, data?.currentUser)!;
};
