const layerStyles = {
  card: {
    bg: "white",
    shadow: "lg",
    p: "3",
    borderRadius: "lg",
  },
  cardWithHover: {
    bg: "white",
    shadow: "lg",
    p: "3",
    borderRadius: "lg",
    willChange: "box-shadow, transform",
    transition: "box-shadow .3s, transform .3s",
    _groupHover: {
      shadow: "2xl",
      transform: "translate3d(0, -4px, 0)",
    },
  },
};

export default layerStyles;
