const shadows = {
  sm: "0px 1px 3px rgba(45, 55, 72, 0.05);",
  md: "0px 2px 8px rgba(45, 55, 72, 0.05);",
  lg: "0px 4px 12px rgba(45, 55, 72, 0.05), 0px 2px 8px rgba(45, 55, 72, 0.03)",
  xl: "0px 6px 16px rgba(45, 55, 72, 0.08), 0px 3px 8px rgba(45, 55, 72, 0.04)",
  "2xl":
    "0px 14px 30px rgba(45, 55, 72, 0.1), 0px 4px 10px rgba(45, 55, 72, 0.04)",
  "3xl": "0px 20px 30px rgba(0, 0, 0, 0.04);",
};

export default shadows;
