import { debounce } from "lodash";
import { callOrReturn } from "made-this-ui";
import {
  useLayoutEffect,
  useCallback,
  useMemo,
  useRef,
  useEffect,
  useState,
} from "react";

export const useDebounced = <T>(initialValue: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(initialValue);
  const [originalValue, setOriginalValue] = useState<T>(initialValue);

  const [_isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    const timeout = setTimeout(() => {
      setDebouncedValue(originalValue);
      setIsLoading(false);
    }, delay);

    return () => clearTimeout(timeout);
  }, [originalValue, delay]);

  useEffect(() => {
    setOriginalValue(initialValue);
  }, [initialValue]);

  return [originalValue, setOriginalValue, debouncedValue] as const;
};

export const useDebouncedCallback = (
  callback: (...args: any[]) => void,
  delay: number
) => {
  const callbackRef = useRef(callback);

  useLayoutEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const debounced = useMemo(
    () => debounce((...args) => callbackRef.current(...args), delay),
    [delay]
  );

  return useCallback(debounced, [debounced]);
};

export const useDebouncedControlledValue = <T>(
  value: T,
  onChange: (value: T) => void,
  delay: number
) => {
  const [innerValue, setInnerValue] = useState(value);

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const handleChange = useDebouncedCallback(onChange, delay);

  return [
    innerValue,
    (value: T) => {
      setInnerValue(value);
      handleChange(value);
    },
  ] as const;
};
