import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { gql, GraphQLClient } from "graphql-request";

export const queryClient = new QueryClient();

export function useGraphqlClient() {
  return new GraphQLClient(`/admin/graphql/public`, {
    credentials: "include",
  });
}

export function useGraphqlQuery(query, variables = {}, config = {}) {
  const client = useGraphqlClient();

  return useQuery(
    [query, variables],
    () => client.request(query, variables),
    config
  );
}

export function useGraphqlMutation(mutation, config = {}) {
  const client = useGraphqlClient();

  return useMutation(
    (variables) => client.request(mutation, variables),
    config
  );
}

const CASE_FRAGMENT = `
  __typename
  id
  state
  stateText
  title
  body
  createdAt
  updatedAt
  mayAddComment
  isArchived
  isService
  isAttending
  serviceDate
  serviceTime
  serviceEmployee
  action
  comments {
    id
    message
    createdAt
    readAt
    from {
      id
      name
    }
    assets {
      id
      createdAt
      url
      fileName
      fileType
      fileSize
    }
  }
  attachments {
    id
    createdAt
    originalUrl
    largeUrl
    thumbUrl
    fileType
    fileSize
    fileName
    isPdf
    isImage
  }
`;

const DOCUMENT_FRAGMENT = `
  id
  title
  createdAt
  url
  type
`;

const INSPECTION_FRAGMENT = `
  tenantSignature
  tenantSignedBy
  landlordSignature
  landlordSignedBy
  cleaning
  other
  answers {
    id
    note
    rating
    area {
      id
      name
    }
    subject {
      id
      name
    }
  }
  document {
    ${DOCUMENT_FRAGMENT}
  }
  keys {
    description
    count
    total
  }
  meters {
    description
    value
  }
`;

// ********************************************
// Queries
// ********************************************

export const ACCOUNT_QUERY = gql`
  query Account {
    account {
      id
      name
      email
      phone
      locale
      address
      postalCode
      city
      country
      countryName
      bankName
      bankReg
      bankAccount
      cpr
      protected
      isEmailConfirmed
      isPhoneConfirmed
    }
  }
`;

export const TENANCIES_QUERY = gql`
  query Tenancies {
    account {
      tenancies {
        __typename
        id
        startDate
        endDate
        isArchived
        isApproved
        apartment {
          id
          address
        }
        residents {
          name
        }
        activities {
          __typename
          ... on CaseInterface {
            id
            title
            action
            isArchived
            unreadCommentsCount
            totalCommentsCount
          }
        }
      }
      requests {
        __typename
        id
        createdAt
        unreadCommentsCount
        totalCommentsCount
        isArchived
        apartment {
          id
          address
        }
        partnerProduct {
          name
          partner
        }
      }
    }
  }
`;

export const TENANCY_QUERY = gql`
  query Tenancy($tenancyId: ID!) {
    tenancy(tenancyId: $tenancyId) {
      id
      startDate
      endDate
      isApproved
      apartment {
        id
        address
        postalCode
        city
      }
      unreadCommentsCount
    }
  }
`;

export const TENANCY_ACTIVITIES_QUERY = gql`
  query TenancyActivities($tenancyId: ID!) {
    tenancy(tenancyId: $tenancyId) {
      activities {
        __typename
        ... on Message {
          id
          createdAt
          updatedAt
          title
          body
        }
        ... on CaseInterface {
          id
          state
          stateText
          title
          body
          isArchived
          createdAt
          updatedAt
          action
          unreadCommentsCount
          totalCommentsCount
        }
      }
    }
  }
`;

export const TENANCY_INFO_QUERY = gql`
  query TenancyInfo($tenancyId: ID!) {
    tenancy(tenancyId: $tenancyId) {
      startDate
      endDate
      isApproved
      documents {
        ${DOCUMENT_FRAGMENT}
      }
      apartment {
        id
        address
        postalCode
        city
      }
      residents {
        id
        name
        email
        phone
      }
      owner {
        name
        address
        postalCode
        city
        email
        phone
      }
      user {
        name
        email
        phone
      }
      endTenancyTerms {
        name
      }
      tenancyRoles {
        user {
          id
          email
          name
          phone
        }
      }
    }
  }
`;

export const TENANCY_SUPPORT_QUERY = gql`
  query TenancySupport($tenancyId: ID!) {
    tenancy(tenancyId: $tenancyId) {
      questions {
        id
        question
        answer
      }
      administrator {
        name
        address
        postalCode
        city
        email
        phone
      }
    }
  }
`;

export const TENANCY_TERMS_QUERY = gql`
  query TenancySupport($tenancyId: ID!, $endDate: ISO8601Date) {
    tenancy(tenancyId: $tenancyId) {
      id
      startDate
      endDate
      apartment {
        id
        address
        postalCode
        city
      }
      endTenancyTerms(endDate: $endDate) {
        name
        endDate
        defaultEndDate
        lastRentDate
        leaveDate
        isEarlyEndDate
      }
    }
  }
`;

export const TENANCY_CASE_QUERY = gql`
  query TenancyCase($tenancyId: ID!, $caseId: ID!) {
    tenancy(tenancyId: $tenancyId) {
      case(caseId: $caseId) {
        ${CASE_FRAGMENT}
        ...on CreateTenancy {
          contract {
            ${DOCUMENT_FRAGMENT}
          }
          startDate
          endDate
          intervalAmount
          depositAmount
          requiresCprNumber
          tenantSignature
          tenantSignedAt
          tenantSignedBy
          landlordSignature
          landlordSignedAt
          landlordSignedBy
          currentUserSignedAt
          signatureVersion
          signatures {
            signature
            signedAt
            signedBy
            signerRole
          }
        }
        ...on EndTenancy {
          comment
          terms {
            name
            endDate
            defaultEndDate
            isEarlyEndDate
            lastRentDate
            leaveDate
          }
        }
        ...on MovingInService {
          inspection {
            ${INSPECTION_FRAGMENT}
          }
        }
        ...on MovingOutService {
          inspection {
            ${INSPECTION_FRAGMENT}
          }
        }
      }
    }
  }
`;

export const TENANCY_MESSAGE_QUERY = gql`
  query TenancyCase($tenancyId: ID!, $messageId: ID!) {
    tenancy(tenancyId: $tenancyId) {
      message(messageId: $messageId) {
        createdAt
        updatedAt
        title
        body
      }
    }
  }
`;

export const REQUEST_QUERY = gql`
  query Request($requestId: ID!) {
    request(requestId: $requestId) {
      ${CASE_FRAGMENT}
      startDate
      endDate
      comment
      isArchived
      numberOfResidents
      apartment {
        id
        address
      }
      partnerProduct {
        name
        partner
      }
      createTenancyCases {
        id
        title
        state
        stateText
        isArchived
        updatedAt
        startDate
        endDate
        tenancy {
          id
          apartment {
            address
          }
        }
      }
    }
  }
`;

export const PROPERTIES_QUERY = gql`
  query Properties {
    properties {
      id
      name
      address
      lat
      lng
      publicPageSlug
      enableInterestList
    }
  }
`;

export const useAccountQuery = (config = {}) =>
  useGraphqlQuery(ACCOUNT_QUERY, {}, config);

export const useTenanciesQuery = (config = {}) =>
  useGraphqlQuery(TENANCIES_QUERY, {}, config);

export const useTenancyQuery = (tenancyId, config = {}) =>
  useGraphqlQuery(TENANCY_QUERY, { tenancyId }, config);

export const useTenancyInfoQuery = (tenancyId, config = {}) =>
  useGraphqlQuery(TENANCY_INFO_QUERY, { tenancyId }, config);

export const useTenancySupportQuery = (tenancyId, config = {}) =>
  useGraphqlQuery(TENANCY_SUPPORT_QUERY, { tenancyId }, config);

export const useTenancyActivitiesQuery = (tenancyId, config = {}) =>
  useGraphqlQuery(TENANCY_ACTIVITIES_QUERY, { tenancyId }, config);

export const useTenancyCaseQuery = ({ tenancyId, caseId }, config = {}) =>
  useGraphqlQuery(TENANCY_CASE_QUERY, { tenancyId, caseId }, config);

export const useTenancyMessageQuery = ({ tenancyId, messageId }, config = {}) =>
  useGraphqlQuery(TENANCY_MESSAGE_QUERY, { tenancyId, messageId }, config);

export const useTenancyTermsQuery = ({ tenancyId, endDate }, config = {}) =>
  useGraphqlQuery(TENANCY_TERMS_QUERY, { tenancyId, endDate }, config);

export const useRequestQuery = (requestId, config = {}) =>
  useGraphqlQuery(REQUEST_QUERY, { requestId }, config);

export const usePropertiesQuery = (config = {}) =>
  useGraphqlQuery(PROPERTIES_QUERY, {}, config);

// ********************************************
// Mutations
// ********************************************

const createMutationHook =
  (q, invalidate = []) =>
  (config = {}) =>
    useGraphqlMutation(q, {
      onSuccess() {
        invalidate.forEach((i) => queryClient.invalidateQueries(i));
      },
      ...config,
    });

export const useAddCaseCommentMutation = createMutationHook(
  gql`
    mutation AddCaseComment($caseId: ID!, $message: String!) {
      addCaseComment(caseId: $caseId, message: $message) {
        errors
      }
    }
  `,
  [TENANCY_ACTIVITIES_QUERY, TENANCY_CASE_QUERY, REQUEST_QUERY]
);

export const useReadCaseCommentMutation = createMutationHook(
  gql`
    mutation ReadCaseComment($commentId: ID!) {
      readCaseComment(commentId: $commentId) {
        errors
      }
    }
  `,
  [
    TENANCIES_QUERY,
    TENANCY_QUERY,
    TENANCY_ACTIVITIES_QUERY,
    TENANCY_CASE_QUERY,
    REQUEST_QUERY,
  ]
);

export const useUpdateResidentsMutation = createMutationHook(
  gql`
    mutation UpdateResidents($tenancyId: ID!, $residents: [ResidentInput!]!) {
      updateResidents(tenancyId: $tenancyId, residents: $residents) {
        errors
      }
    }
  `,
  [TENANCY_INFO_QUERY]
);

export const useUpdateAccountNameMutation = createMutationHook(
  gql`
    mutation UpdateAccountName($name: String!) {
      updateAccountName(name: $name) {
        errors
      }
    }
  `,
  [ACCOUNT_QUERY, TENANCY_INFO_QUERY]
);

export const useUpdateAccountAddressMutation = createMutationHook(
  gql`
    mutation UpdateAccountAddress(
      $address: String!
      $postalCode: String!
      $city: String!
      $country: String!
    ) {
      updateAccountAddress(
        address: $address
        postalCode: $postalCode
        city: $city
        country: $country
      ) {
        errors
      }
    }
  `,
  [ACCOUNT_QUERY]
);

export const useUpdateAccountBankMutation = createMutationHook(
  gql`
    mutation UpdateAccountBank(
      $bankName: String!
      $bankReg: String!
      $bankAccount: String!
    ) {
      updateAccountBank(
        bankName: $bankName
        bankReg: $bankReg
        bankAccount: $bankAccount
      ) {
        errors
      }
    }
  `,
  [ACCOUNT_QUERY]
);

export const useUpdateAccountCprMutation = createMutationHook(
  gql`
    mutation UpdateAccountCpr($cpr: String!) {
      updateAccountCpr(cpr: $cpr) {
        errors
      }
    }
  `,
  [ACCOUNT_QUERY, TENANCY_INFO_QUERY]
);

export const useUpdateAccountLocaleMutation = createMutationHook(
  gql`
    mutation UpdateAccountLocale($locale: String!) {
      updateAccountLocale(locale: $locale) {
        errors
      }
    }
  `,
  [ACCOUNT_QUERY]
);

export const useUpdateAccountProtectedMutation = createMutationHook(
  gql`
    mutation UpdateAccountProtected($protected: Boolean!) {
      updateAccountProtected(protected: $protected) {
        errors
      }
    }
  `,
  [ACCOUNT_QUERY]
);

export const useUpdateAccountEmailMutation = createMutationHook(
  gql`
    mutation UpdateAccountEmail($email: String!) {
      updateAccountEmail(email: $email) {
        errors
      }
    }
  `,
  [ACCOUNT_QUERY]
);

export const useUpdateAccountPhoneMutation = createMutationHook(
  gql`
    mutation UpdateAccountPhone($phone: String!, $token: String) {
      updateAccountPhone(phone: $phone, token: $token) {
        errors
      }
    }
  `,
  [ACCOUNT_QUERY, TENANCY_INFO_QUERY]
);

export const useUpdateAccountPasswordMutation = createMutationHook(
  gql`
    mutation UpdateAccountPassword($password: String!) {
      updateAccountPassword(password: $password) {
        errors
      }
    }
  `,
  []
);

export const useEndTenancyMutation = createMutationHook(
  gql`
    mutation EndTenancy(
      $tenancyId: ID!
      $endDate: ISO8601Date!
      $comment: String
    ) {
      endTenancy(tenancyId: $tenancyId, endDate: $endDate, comment: $comment) {
        errors
        case {
          id
        }
      }
    }
  `,
  []
);

export const useSignTenancyContractMutation = createMutationHook(
  gql`
    mutation SignTenancyContractTenancy(
      $caseId: ID!
      $signature: String!
      $cprNumber: String
    ) {
      signTenancyContract(
        caseId: $caseId
        signature: $signature
        cprNumber: $cprNumber
      ) {
        errors
        case {
          id
        }
      }
    }
  `,
  [TENANCY_CASE_QUERY, TENANCY_ACTIVITIES_QUERY]
);

export const useMovingInformationMutation = createMutationHook(
  gql`
    mutation MovingInformation(
      $caseId: ID!
      $address: String!
      $postalCode: String!
      $city: String!
      $country: String!
      $bankName: String!
      $bankReg: String!
      $bankAccount: String!
    ) {
      movingInformation(
        caseId: $caseId
        address: $address
        postalCode: $postalCode
        city: $city
        country: $country
        bankName: $bankName
        bankReg: $bankReg
        bankAccount: $bankAccount
      ) {
        errors
        case {
          id
        }
      }
    }
  `,
  [TENANCY_CASE_QUERY, TENANCY_ACTIVITIES_QUERY, ACCOUNT_QUERY]
);

export const useChangeCaseAttendanceMutation = createMutationHook(
  gql`
    mutation ChangeCaseAttendance($caseId: ID!, $isAttending: Boolean!) {
      changeCaseAttendance(caseId: $caseId, isAttending: $isAttending) {
        errors
      }
    }
  `,
  [TENANCY_CASE_QUERY, TENANCY_ACTIVITIES_QUERY]
);

export const useAddInterestEntryMutation = createMutationHook(
  gql`
    mutation AddInterestEntry(
      $name: String!
      $email: String!
      $phone: String!
      $propertyIds: [ID!]!
      $message: String
    ) {
      addInterestEntry(
        name: $name
        email: $email
        phone: $phone
        propertyIds: $propertyIds
        message: $message
      ) {
        errors
      }
    }
  `,
  []
);
