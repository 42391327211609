import { useTheme } from "@chakra-ui/react";
import { withFooterActionsDataProvider } from "./FooterActionsDataProvider";
import {
  FooterPopoverInbox,
  FooterMenu,
  FooterPopoverMessages,
  FooterPopoverFlagged,
} from ".";

export const FooterActions = withFooterActionsDataProvider(({ actionData }) => {
  const messagesCount = actionData.messages.records.length;
  const flaggedCount = actionData.flagged.records.length;
  const inboxCount = actionData.inbox.records.length;
  const {
    icons: { IconMessages, IconFlag, IconInbox },
  } = useTheme();
  return (
    <>
      <FooterMenu
        label="Messages"
        notificationCount={messagesCount}
        content={
          <FooterPopoverMessages records={actionData.messages.records} />
        }
        icon={IconMessages}
      />
      <FooterMenu
        label="Flagged"
        notificationCount={flaggedCount}
        content={<FooterPopoverFlagged records={actionData.flagged.records} />}
        icon={IconFlag}
      />
      <FooterMenu
        label="Inbox"
        notificationCount={inboxCount}
        content={<FooterPopoverInbox records={actionData.inbox.records} />}
        icon={IconInbox}
      />
    </>
  );
});
