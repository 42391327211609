import { ComponentWithAs, HStack, IconProps, Text } from "@chakra-ui/react";

interface FooterPopoverTitleProps {
  icon?: ComponentWithAs<"svg", IconProps>;
  title: string;
}

export const FooterPopoverTitle = ({
  title,
  icon: Icon,
}: FooterPopoverTitleProps) => {
  return (
    <HStack>
      {Icon && <Icon fontSize={28} />}
      <Text textStyle="sub-md-strong" color="primary.800">
        {title}
      </Text>
    </HStack>
  );
};
