const Checkbox = {
  sizes: {
    md: {
      label: {
        textStyle: "bodyNormal",
      },
    },
  },
};

export default Checkbox;
