module.exports = {
  locales: ["da", "en"],
  defaultLocale: "da",
  pages: {
    "*": ["common"],
    "/home/[[...home]]": ["home"],
    "rgx:^/authorize": ["authorize"],
    "rgx:^/next/admin": ["admin", "rails"],
  },
};
