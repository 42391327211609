import {
  Box,
  ComponentWithAs,
  IconProps,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { KbdNavigation } from "admin/components/common/KbdNavigation";
import { useAppContext } from "made-this-ui";
import { ToolButton } from "../ToolButton";

export interface FooterMenuProps {
  icon: ComponentWithAs<"svg", IconProps>;
  label: string;
  notificationCount?: number;
  content: ReactNode;
}

export const FooterMenu = ({
  icon: Icon,
  label,
  content,
  notificationCount = 0,
}: FooterMenuProps) => {
  const { isCollapsed } = useAppContext();

  if (isCollapsed && !notificationCount) return null;

  return (
    <Popover
      isOpen={notificationCount === 0 ? false : undefined}
      placement="bottom-end"
      returnFocusOnClose={false}
    >
      <PopoverTrigger>
        <Box position="relative">
          <ToolButton
            icon={Icon}
            label={label}
            count={notificationCount ? notificationCount : undefined}
          />
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent w={"500px"}>
          <PopoverBody p={6} bg="white">
            {content}
            <KbdNavigation mt={4} />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
