import { BoxProps, useTheme } from "@chakra-ui/react";
import {
  CaseAttentionEnum,
  CaseTypeEnum,
} from "admin/graphql/generated/graphql";

export type IconCaseProps = BoxProps & {
  case?: {
    type?: CaseTypeEnum;
    attention?: CaseAttentionEnum;
    awaiting?: boolean | null | Date | string;
    state?: string;
  };
};

export function IconCase({
  case: { type, attention, awaiting, state } = {},
  ...rest
}: IconCaseProps) {
  const {
    icons: {
      IconCaseCreateTenancy,
      IconCaseEndTenancy,
      IconCaseMiscAdministration,
      IconCaseFindTenant,
      IconCaseUtility,
      IconCaseMovingInInspection,
      IconCaseMovingOutInspection,
      IconCaseMovingSettlement,
      IconCaseRequestTenancy,
      IconCaseMovingIn,
      IconCaseMovingOut,
      IconCaseMiscService,
      IconCaseCleaning,
      IconCasePreparation,
      IconDot,
    },
  } = useTheme();

  const getIcon = () => {
    switch (type) {
      case CaseTypeEnum.CreateTenancy:
        return IconCaseCreateTenancy;
      case CaseTypeEnum.EndTenancy:
        return IconCaseEndTenancy;
      case CaseTypeEnum.MiscAdministration:
        return IconCaseMiscAdministration;
      case CaseTypeEnum.FindTenancyAdministration:
        return IconCaseFindTenant;
      case CaseTypeEnum.UtilityAdministration:
        return IconCaseUtility;
      case CaseTypeEnum.MovingInService:
        return IconCaseMovingInInspection;
      case CaseTypeEnum.MovingOutService:
        return IconCaseMovingOutInspection;
      case CaseTypeEnum.MovingSettlementAdministration:
        return IconCaseMovingSettlement;
      case CaseTypeEnum.RequestTenancy:
        return IconCaseRequestTenancy;
      case CaseTypeEnum.MovingInTenancy:
        return IconCaseMovingIn;
      case CaseTypeEnum.MovingOutTenancy:
        return IconCaseMovingOut;
      case CaseTypeEnum.MiscService:
        return IconCaseMiscService;
      case CaseTypeEnum.CleaningService:
        return IconCaseCleaning;
      case CaseTypeEnum.PreparationService:
        return IconCasePreparation;
      default:
        return IconDot;
    }
  };

  let color = "gray.500";
  if (attention === CaseAttentionEnum.Normal) color = "primary.500";
  if (awaiting) color = "info.500";
  if (attention === CaseAttentionEnum.Critical) color = "danger.500";
  if (attention === CaseAttentionEnum.Important) color = "warning.500";
  if (attention === CaseAttentionEnum.Success || state === "completed")
    color = "success.500";
  if (state === "cancelled") color = "gray.500";

  const Icon = getIcon();

  return <Icon boxSize="5" color={color} {...rest} />;
}
