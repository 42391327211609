import { graphql } from "admin/graphql/generated";
import { useGraphQLMutation } from "made-this-ui";
import { useQueryClient } from "@tanstack/react-query";

export const ShortcutRemoveMutation = graphql(/* GraphQL */ `
  mutation ShortcutRemove($id: ID!) {
    shortcutDelete(shortcutId: $id) {
      shortcutId
      errors
    }
  }
`);

export const useShortcutListRemove = () => {
  const qc = useQueryClient();
  return useGraphQLMutation(ShortcutRemoveMutation, {
    onSuccess: (res) => {
      if (res.shortcutDelete?.errors) {
        console.error(res.shortcutDelete.errors);
        return;
      }

      qc.invalidateQueries();
    },
  });
};
