import { Stack, Text } from "@chakra-ui/react";
import {
  GlobalSearchGroupItem,
  GlobalSearchGroupItemItemType,
} from "./GlobalSearchGroupItem";
import { SearchNavigatorItem } from "./GlobalSearchModal";

export interface GlobalSearchGroupProps {
  title: string;
  records: GlobalSearchGroupItemItemType[];
  selectedItemId?: number;
  type: "tenancy" | "case";
  navigateToSearchItem: (params: SearchNavigatorItem) => void;
}

export const GlobalSearchGroup = ({
  type,
  title,
  records,
  selectedItemId,
  navigateToSearchItem,
}: GlobalSearchGroupProps) => {
  if (records.length === 0) {
    return null;
  }

  return (
    <Stack spacing={0} mb="4">
      <Text
        py={1}
        px={3}
        textStyle={"body-md"}
        color={"gray.500"}
        textTransform="uppercase"
      >
        {title}
      </Text>
      <Stack spacing={1}>
        {records.map((record) => (
          <GlobalSearchGroupItem
            id={`search-item-${type}-${record.id}`}
            isSelected={selectedItemId === record.id}
            item={record}
            key={record.id}
            handleClick={() => navigateToSearchItem({ type, id: record.id })}
          />
        ))}
      </Stack>
    </Stack>
  );
};
