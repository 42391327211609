import { Box, useTheme } from "@chakra-ui/react";
import {
  FooterPopoverTitle,
  CaseDefinitionCaseFragmentType,
  FooterPopoverNotificationItem,
} from ".";
import { IconCase } from "admin/components/common/IconCase";
import { useNavigationBetweenPopoverItems } from "admin/lib/useNavigationBetweenPopoverItems";
import { useAdminTranslation } from "admin/lib/useAdminTranslation";

interface FooterPopoverFlaggedProps {
  records: CaseDefinitionCaseFragmentType[];
}

export const FooterPopoverFlagged = ({
  records,
}: FooterPopoverFlaggedProps) => {
  const {
    icons: { IconFlag },
  } = useTheme();
  const { activeIdx } = useNavigationBetweenPopoverItems(records);
  const { t } = useAdminTranslation();

  return (
    <Box>
      <FooterPopoverTitle icon={IconFlag} title={t("concepts.flagged")} />
      <Box mt={4}>
        {records.map((item, idx) => (
          <FooterPopoverNotificationItem
            key={item.id}
            icon={<IconCase case={item} />}
            title={item.shortName}
            description={item.name}
            isActive={activeIdx === idx}
            href={`/next/admin/cases/${item.id}`}
          />
        ))}
      </Box>
    </Box>
  );
};
