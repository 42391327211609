import {
  Button,
  ButtonProps,
  Center,
  CenterProps,
  ComponentWithAs,
  IconButton,
  IconProps,
  Text,
  useDisclosure,
  useTheme,
} from "@chakra-ui/react";
import { useRef } from "react";
import { PopoverAnimated } from "./PopoverAnimated";

interface EmptyContentProps {
  icon: ComponentWithAs<"svg", IconProps>;
  title: string;
  description?: string;
  buttonProps?: ButtonProps;
  hint?: string;
}

export const EmptyContent = ({
  icon: Icon,
  hint,
  title,
  buttonProps,
  description,
  ...props
}: EmptyContentProps & CenterProps) => {
  const containerRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    icons: { IconQuestion },
  } = useTheme();

  return (
    <Center
      ref={containerRef}
      bg="linear-gradient(180deg, #E7F3F6 0%, #FFFFFF 100%)"
      pos="relative"
      py={8}
      flexDir="column"
      {...props}
    >
      <Center w={10} h={10} rounded="full" bg="white">
        <Icon color="primary.500" fontSize={28} />
      </Center>

      <Text
        textStyle="body-sm-heavy"
        color="primary.600"
        my={2}
        textAlign="center"
      >
        {title}
      </Text>
      {description && (
        <Text textStyle="body-sm" color="gray.500">
          {description}
        </Text>
      )}

      {buttonProps && <Button mt={6} colorScheme="primary" {...buttonProps} />}
      {hint && (
        <>
          <IconButton
            size="xs"
            w={6}
            h={6}
            icon={<IconQuestion color="gray.600" />}
            aria-label="hint popover"
            onClick={onOpen}
            pos="absolute"
            top={2}
            right={2}
            bg="white"
            rounded="full"
          />

          <PopoverAnimated
            portalContainerRef={containerRef}
            isOpen={isOpen}
            onClose={onClose}
            top="38px"
            right={3}
            maxW={193}
            rounded="sm"
          >
            <Text textStyle="body-xs" color="gray.800">
              {hint}
            </Text>
          </PopoverAnimated>
        </>
      )}
    </Center>
  );
};
