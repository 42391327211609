import { graphql } from "admin/graphql/generated";
import { useGraphQLQuery } from "made-this-ui";
import { GlobalSearchGroupItemItemType } from "./GlobalSearchGroupItem";
import { useEffect } from "react";
import { useDebounced } from "admin/lib/useDebounced";

const GlobalSearchQuery = graphql(/* GraphQL */ `
  query GlobalSearchQuery($searchValue: String!) {
    tenancies(where: { search: $searchValue, archived: false }, limit: 10) {
      records {
        id
        user {
          id
          name
          __typename
        }
        apartment {
          id
          address
          __typename
        }
        __typename
      }
      __typename
    }
    cases(where: { search: $searchValue, archived: false }, limit: 10) {
      records {
        id
        name
        shortName
        stateText
        apartment {
          id
          address
          __typename
        }
        __typename
      }
      __typename
    }
  }
`);

const useGlobalSearchQuery = (searchValue: string) => {
  return useGraphQLQuery(GlobalSearchQuery, {
    variables: { searchValue },
    enabled: searchValue.length > 0,
  });
};

export enum GlobalSearchType {
  TENANT = "tenant",
  CASE = "case",
}

export type GlobalSearchRecordsType<T extends GlobalSearchType> = {
  records: GlobalSearchGroupItemItemType[];
  type: T;
};

export type GlobalSearchResultType = {
  tenants: GlobalSearchRecordsType<GlobalSearchType.TENANT>;
  cases: GlobalSearchRecordsType<GlobalSearchType.CASE>;
  isLoading: boolean;
  totalCount: number;
  debouncedSearchValue: string;
};

export const useGlobalSearch = (
  searchValue: string
): GlobalSearchResultType => {
  const [current, setCurrent, debouncedSearchValue] = useDebounced(
    searchValue,
    500
  );

  useEffect(() => {
    setCurrent(searchValue);
  }, [searchValue, setCurrent]);

  const { data, isLoading } = useGlobalSearchQuery(debouncedSearchValue);

  const tenantsResult: GlobalSearchGroupItemItemType[] =
    data?.tenancies.records.map((tenancy) => ({
      id: tenancy.id,
      title: tenancy.user.name,
      subTitle: tenancy.apartment.address,
      helperText: ``,
    })) || [];

  const casesResult: GlobalSearchGroupItemItemType[] =
    data?.cases?.records.map((caseItem) => ({
      id: caseItem.id,
      title: caseItem.name,
      subTitle: caseItem?.apartment?.address || "",
      helperText: `#${caseItem.id} - ${caseItem.stateText}`,
    })) || [];

  return {
    tenants: {
      type: GlobalSearchType.TENANT,
      records: tenantsResult,
    },
    cases: {
      type: GlobalSearchType.CASE,
      records: casesResult,
    },
    isLoading,
    totalCount: tenantsResult.length + casesResult.length,
    debouncedSearchValue,
  };
};
