import { graphql } from "admin/graphql/generated";
import { useGraphQLQuery } from "made-this-ui";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";

export const ShortcutControlQuery = graphql(/* GraphQL */ `
  query ShortcutControl($shortcutId: ID!) {
    currentUser {
      shortcut(shortcutId: $shortcutId) {
        id
        type
        name
      }
    }
  }
`);

type ShortControlQueryType = GetQueryData<typeof ShortcutControlQuery>;

type GetQueryData<T> = T extends TypedDocumentNode<infer U, any> ? U : never;

export const useShortcutControlQuery = (
  shortcutId: string,
  options?: {
    onComplete?: (data: ShortControlQueryType) => void;
  }
) =>
  useGraphQLQuery(ShortcutControlQuery, {
    variables: {
      shortcutId,
    },
    onSuccess: options?.onComplete,
  });
