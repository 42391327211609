import { ShortcutsListShortcut } from "./useShortcutsList";
import { Box, List } from "@chakra-ui/react";
import { ListItemShortcut } from "./ListItemShortcut";
import { KbdNavigation } from "admin/components/common/KbdNavigation";

export interface ListShortcutsProps {
  items: ShortcutsListShortcut[];
  onItemClick?: (item: ShortcutsListShortcut) => void;
  onItemRemoveClick?: (item: ShortcutsListShortcut) => void;
  activeItemId: string | null;
}

export const ListShortcuts = ({
  items,
  onItemClick,
  onItemRemoveClick,
  activeItemId,
}: ListShortcutsProps) => {
  return (
    <>
      <List as={"div"} height={"240px"} overflow={"auto"}>
        {items.map((item) => (
          <ListItemShortcut
            isActive={activeItemId === item.id}
            key={item.id}
            item={item}
            onItemClick={onItemClick}
            onItemRemoveClick={onItemRemoveClick}
          />
        ))}
      </List>
      <Box>
        <KbdNavigation mt={4} pr={3} />
      </Box>
    </>
  );
};
