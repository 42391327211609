import { Bootstrap } from "made-this-ui";
import { AppProps } from "next/app";
import Head from "next/head";
import { queryClient } from "../lib/graphql";
import ejsingholmTheme from "../lib/theme";

function HomeApp({ Component, pageProps }: AppProps) {
  return (
    <Bootstrap theme={ejsingholmTheme} queryClient={queryClient}>
      <Head>
        <title>Ejsingholm</title>
        <meta
          name="viewport"
          content="initial-scale=1.0, width=device-width, viewport-fit=cover"
        />
      </Head>
      <Component {...pageProps} />
    </Bootstrap>
  );
}

export default HomeApp;
