import { Box, Text, useToast } from "@chakra-ui/react";
import { NoShortcuts } from "./NoShortcuts";
import { ShortcutsListShortcut, useShortcutsList } from "./useShortcutsList";
import { SearchableShortcuts } from "./SearchableShortcuts";
import { useState } from "react";
import { useDebouncedControlledValue } from "admin/lib/useDebounced";
import {
  getPathFromPathIdentifier,
  ShortcutPathIdentifier,
} from "../useShortcutPathMap";
import { useRouter } from "next/router";
import { useAdminTranslation } from "admin/lib/useAdminTranslation";
import { useShortcutListRemove } from "./useShortcutListRemove";

interface ShortcutsViewProps {
  onNewShortcutToggle: () => void;
  onViewShortcutsClose: () => void;
}

export const ShortcutsView = ({
  onNewShortcutToggle,
  onViewShortcutsClose,
}: ShortcutsViewProps) => {
  const router = useRouter();
  const toast = useToast();
  const { t, model } = useAdminTranslation();

  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");
  const [searchValue, setSearchValue] = useDebouncedControlledValue(
    debouncedSearchValue,
    (value: string) => {
      setDebouncedSearchValue(value);
    },
    500
  );

  const { data, isInitialLoading } = useShortcutsList({
    where: {
      query: debouncedSearchValue.length > 0 ? debouncedSearchValue : undefined,
    },
  });

  const { mutateAsync: removeItem } = useShortcutListRemove();

  const totalCount = data?.currentUser?.shortcutsTotal?.totalCount || 0;
  const shortcuts = (data?.currentUser?.shortcuts?.nodes ||
    []) as ShortcutsListShortcut[];

  const handleNewShortcutPopupOpen = () => {
    onViewShortcutsClose();
    onNewShortcutToggle();
  };

  const handleItemClicked = (item: ShortcutsListShortcut) => {
    const path = getPathFromPathIdentifier(item.type as ShortcutPathIdentifier);
    router.push({
      pathname: path,
      query: {
        sid: item.id,
      },
    });
  };

  const handleItemRemoveClicked = async (item: ShortcutsListShortcut) => {
    try {
      const res = await removeItem({
        id: item.id,
      });
      if (res.shortcutDelete?.errors) {
        console.error(res.shortcutDelete.errors);

        toast({
          position: "top",
          status: "error",
          title: t("texts.shortcuts.remove_request_error"),
        });
        return;
      }

      toast({
        position: "top",
        status: "success",
        title: t("texts.shortcuts.remove_success_message"),
      });
    } catch (e) {
      toast({
        position: "top",
        status: "error",
        title: t("texts.shortcuts.remove_request_error"),
      });
    }
  };

  return (
    <Box>
      <Text as="h1" textStyle="sub-md-strong" color="primary.800" mb={5}>
        {model("shortcut", { count: 2 })}
      </Text>

      {!isInitialLoading ? (
        <>
          {totalCount === 0 ? (
            <NoShortcuts
              onOpenCreateShortcutPopup={handleNewShortcutPopupOpen}
            />
          ) : (
            <SearchableShortcuts
              onClose={onViewShortcutsClose}
              onItemClick={handleItemClicked}
              onItemRemoveClick={handleItemRemoveClicked}
              search={searchValue}
              onSearchChange={setSearchValue}
              shortcuts={shortcuts}
            />
          )}
        </>
      ) : null}
    </Box>
  );
};
