import { useTheme } from "@chakra-ui/react";
import { EmptyContent } from "admin/components/common/EmptyContent";
import { useAdminTranslation } from "admin/lib/useAdminTranslation";

export interface NoShortcutsProps {
  onOpenCreateShortcutPopup: () => void;
}

export const NoShortcuts = ({
  onOpenCreateShortcutPopup,
}: NoShortcutsProps) => {
  const {
    icons: { IconShortcut },
  } = useTheme();
  const { t } = useAdminTranslation();

  return (
    <EmptyContent
      title={t("texts.shortcuts.no_content_title")}
      icon={IconShortcut}
      description={t("texts.shortcuts.no_content_description")}
      hint={t("texts.shortcuts.no_content_hint")}
      buttonProps={{
        children: t("texts.shortcuts.no_content_button"),
        onClick: onOpenCreateShortcutPopup,
      }}
      rounded="md"
    />
  );
};
