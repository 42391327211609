import useTranslation from "next-translate/useTranslation";
import { useCallback } from "react";

export function useAdminTranslation() {
  const { t, lang } = useTranslation("admin");

  const attr = useCallback(
    (model_or_attribute: string, attribute: string | undefined = undefined) => {
      if (attribute) {
        return t(
          `rails:models.activerecord.attributes.${model_or_attribute}.${attribute}`,
          undefined,
          {
            fallback: `rails:models.attributes.${attribute}`,
          }
        );
      } else {
        return t(`rails:models.attributes.${model_or_attribute}`);
      }
    },
    [t]
  );

  const model = useCallback(
    (model: string, options = { count: 1 }) => {
      return t(`rails:models.activerecord.models.${model}`, options);
    },
    [t]
  );

  const date = useCallback(
    (
      date: string | Date | undefined | null,
      options:
        | Intl.DateTimeFormatOptions
        | Intl.DateTimeFormatOptions["dateStyle"]
        | [
            Intl.DateTimeFormatOptions["dateStyle"],
            Intl.DateTimeFormatOptions["timeStyle"]
          ]
        | undefined = undefined
    ) => {
      if (!date) return null;

      let resolvedOptions;

      if (Array.isArray(options)) {
        resolvedOptions = {
          dateStyle: options[0],
          timeStyle: options[1],
        };
      } else if (typeof options === "string") {
        resolvedOptions = {
          dateStyle: options,
        };
      } else {
        resolvedOptions = options;
      }

      return new Intl.DateTimeFormat(lang, resolvedOptions).format(
        new Date(date)
      );
    },
    [lang]
  );

  const currency = useCallback(
    (
      amount: number | bigint | undefined | null,
      options: Intl.NumberFormatOptions | undefined = undefined
    ) => {
      if (amount == null) return null;

      return new Intl.NumberFormat(lang, {
        style: "currency",
        currency: "DKK",
        maximumFractionDigits: 0,
        ...options,
      }).format(amount);
    },
    [lang]
  );

  const number = useCallback(
    (
      number: number | bigint | undefined | null,
      options:
        | Intl.NumberFormatOptions
        | Intl.NumberFormatOptions["style"]
        | undefined = undefined
    ) => {
      if (number == null) return null;

      let resolvedOptions;

      if (typeof options === "string") {
        resolvedOptions = {
          style: options,
        };
      } else {
        resolvedOptions = options;
      }

      return new Intl.NumberFormat(lang, resolvedOptions).format(number);
    },
    [lang]
  );

  return {
    t,
    date,
    number,
    currency,
    attr,
    model,
    lang,
  };
}
