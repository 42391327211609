import { AppProps } from "next/app";
import { LayoutDocs } from "admin/components/layouts/LayoutDocs";
import { Bootstrap } from "made-this-ui";

import themeAdmin from "admin/lib/theme";
import { useCurrentUserQuery } from "admin/lib/useCurrentUser";
import { ReactNode } from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { CurrentUserQuery } from "admin/graphql/generated/graphql";

export interface DocsAppProps extends AppProps {}

export const DocsApp = ({ Component, pageProps }: DocsAppProps) => {
  return (
    <Bootstrap
      theme={themeAdmin}
      graphqlOptions={{ endpoint: "/admin/graphql/admin" }}
    >
      <CurrentUser>
        {(userQuery) => (
          <LayoutDocs user={userQuery.data?.currentUser}>
            {!userQuery.isLoading && (
              <Component
                {...pageProps}
                currentUser={userQuery.data?.currentUser}
              />
            )}
          </LayoutDocs>
        )}
      </CurrentUser>
    </Bootstrap>
  );
};

const CurrentUser = ({
  children,
}: {
  children: (userQuery: UseQueryResult<CurrentUserQuery>) => ReactNode;
}) => {
  const userQuery = useCurrentUserQuery();
  return <>{children(userQuery)}</>;
};
