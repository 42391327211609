import { themeEjsingholm } from "made-this-ui";
import { extendTheme } from "@chakra-ui/react";

// TODO: move this to made-this-ui when we have establised the theme
import * as icons from "./icons";
import * as caseIcons from "./caseIcons";

const colors = {
  background: "#F9FCFC",
  primary: {
    50: "#F9FCFC",
    100: "#E7F2F5",
    200: "#BBDCE4",
    300: "#79BACA",
    400: "#3491AA",
    500: "#0C738E",
    600: "#00617B",
    700: "#00485E",
    800: "#00303F",
    900: "#001E28",
  },
  secondary: {
    50: "#FBFBFC",
    100: "#EFF0F2",
    200: "#D3D7DB",
    300: "#AAB0B8",
    400: "#7D8793",
    500: "#606B7A",
    600: "#4E5A6B",
    700: "#354356",
    800: "#222C39",
    900: "#151B24",
  },
  primaryAlpha: {
    50: "rgba(12, 115, 142, 0.03)",
    100: "rgba(12, 115, 142, 0.07)",
    200: "rgba(12, 115, 142, 0.1)",
    300: "rgba(12, 115, 142, 0.16)",
    400: "rgba(12, 115, 142, 0.24)",
    500: "rgba(12, 115, 142, 0.36)",
    600: "rgba(12, 115, 142, 0.48)",
    700: "rgba(12, 115, 142, 0.64)",
    800: "rgba(12, 115, 142, 0.8)",
    900: "rgba(12, 115, 142, 0.92)",
  },
};

const fonts = {
  heading: `Geomanist, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
};

const textStyles = {
  "head-xs": {
    fontWeight: "medium",
  },
  "head-sm": {
    fontWeight: "medium",
  },
  "head-md": {
    fontWeight: "medium",
  },
  "head-lg": {
    fontWeight: "medium",
  },
  "head-xl": {
    fontWeight: "medium",
  },
};

const components = {
  ScreenSectionHeader: {
    baseStyle: {
      title: {
        whiteSpace: "nowrap",
      },
    },
  },
  Kbd: {
    baseStyle: {
      p: 1,
      bg: "gray.100",
      minH: 5,
      minW: 5,
      border: "none",
      display: "inline-block",
      textAlign: "center",
      borderRadius: "sm",
      fontFamily: "body",
      textTransform: "uppercase",
      color: "gray.400",
      fontSize: "2xs",
      fontWeight: "regular",
    },
  },
  Heading: {
    defaultProps: {
      fontWeight: "medium",
      size: "md",
    },
  },
  DataTable: {
    baseStyle: {
      rowData: {
        "&[data-clickable=true]:hover": {
          bgColor: "rgb(245,250,251)",
        },
      },
      cellData: {
        color: "gray.700",
        borderColor: "gray.100",
        borderLeftColor: "gray.200",
        borderRightColor: "gray.200",
        "table [data-clickable=true]:hover > &": {
          bgColor: "rgb(245,250,251)",
        },
        _last: {
          bgGradient: `linear(to-r, rgba(255,255,255,0), white)`,
        },
        "table [data-clickable=true][data-action=true]:hover &:last-child": {
          bgGradient: `linear(to-r, rgb(245,250,251), rgb(245,250,251), rgb(245,250,251))`,
        },
        "table [data-clickable=true][data-action=false]:hover &:last-child": {
          bgGradient: `linear(to-r, rgba(245,250,251,0), rgb(245,250,251))`,
        },
        "table [data-clickable=false][data-action=true]:hover &:last-child": {
          bgGradient: `linear(to-r, rgb(255,255,255), white, white)`,
        },
      },
      cellHead: {
        color: "gray.900",
        borderColor: "gray.200",
      },
      pagination: {
        borderColor: "gray.200",
      },
    },
    sizes: {
      sm: {
        cellHead: {
          h: 12,
        },
      },
    },
    defaultProps: {
      size: "sm",
    },
  },
  Drawer: {
    sizes: {
      "75p": {
        dialog: {
          maxW: "75%",
        },
      },
    },
  },
  Modal: {
    sizes: {
      iframe: {
        dialog: {
          maxW: ["90vw", "80vw", "70vw"],
          minH: "90vh",
          mt: 8,
          mb: 2,
        },
      },
    },
  },
  Popover: {
    baseStyle: {
      content: {
        borderRadius: "md",
        boxShadow: "xl",
        overflow: "hidden",
        _focusVisible: {
          outline: "none",
          boxShadow: "xl",
        },
      },
    },
  },
  App: {
    baseStyle: {
      navigationItem: {
        _hover: {
          bg: "primary.400",
        },
        _active: {
          bg: "blackAlpha.200",
        },
      },
    },
  },
  Badge: {
    baseStyle: {
      padding: 1,
    },
  },
};

let theme = extendTheme(
  {
    colors,
    fonts,
    textStyles,
    components,
  },
  themeEjsingholm
);

theme.icons = {
  ...themeEjsingholm.icons,
  ...icons,
  ...caseIcons,
};

theme.fontSources = {
  inter: "https://rsms.me/inter/inter.css",
  geomanist: "https://ui.madethis.dev/fonts/geomanist/geomanist.css",
};

export default theme;
