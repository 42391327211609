import { Box, Flex, Link, Text } from "@chakra-ui/react";
import NextLink from "next/link";
import { ReactNode } from "react";

interface FooterPopoverNotificationItemProps {
  icon: ReactNode;
  title: string;
  description: string;
  isActive: boolean;
  href: string;
}

export const FooterPopoverNotificationItem = ({
  icon,
  title,
  isActive,
  description,
  href,
}: FooterPopoverNotificationItemProps) => {
  return (
    <NextLink href={href} passHref>
      <Flex
        p={2}
        transition="0.25s ease all"
        cursor="pointer"
        bg={isActive ? "primaryAlpha.100" : "transparent"}
        _hover={{
          bg: "primaryAlpha.100",
        }}
        as={Link}
        style={{ textDecoration: "none" }}
      >
        <Box boxSize="4" mr={1}>
          {icon}
        </Box>
        <Box ml={2}>
          <Text textStyle="body-md-strong" mb={1} color="gray.900">
            {title}
          </Text>
          <Text textStyle="body-md" color="gray.600">
            {description}
          </Text>
        </Box>
      </Flex>
    </NextLink>
  );
};
