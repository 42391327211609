import { Stack, Text } from "@chakra-ui/react";

export type GlobalSearchGroupItemItemType = {
  id: any;
  title: string;
  subTitle: string;
  helperText: string;
};

export interface GlobalSearchGroupItemProps {
  handleClick: () => void;
  isSelected?: boolean;
  item: GlobalSearchGroupItemItemType;
  id: string;
}

export const GlobalSearchGroupItem = ({
  handleClick,
  isSelected,
  item: { title, subTitle, helperText },
  id,
}: GlobalSearchGroupItemProps) => {
  const bgColor = isSelected ? "primaryAlpha.100" : "transparent";

  return (
    <Stack
      id={id}
      cursor={"pointer"}
      spacing={1}
      bg={bgColor}
      _hover={{ bg: "primaryAlpha.100" }}
      borderRadius={4}
      py={2}
      px={3}
      onClick={handleClick}
    >
      <Text textStyle={"body-sm-strong"} color={"gray.900"}>
        {title}
      </Text>
      <Text textStyle={"body-sm"} color={"gray.900"}>
        {subTitle}
      </Text>
      <Text textStyle={"body-xs"} color={"gray.400"}>
        {helperText}
      </Text>
    </Stack>
  );
};
