
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import { useRouter } from "next/router";
import HomeApp from "home/components/HomeApp";
import AdminApp from "admin/components/AdminApp";

import "../css/main.css";
import { DocsApp } from "admin/components/DocsApp";

function Ejsingholm({ Component, pageProps }) {
  const { pathname } = useRouter();

  if (Component.root) return <Component {...pageProps} />;

  const App = determineApp(pathname);

  return <App Component={Component} pageProps={pageProps} />;
}

const __Page_Next_Translate__ = Ejsingholm;

const determineApp = (pathname) => {
  if (pathname.startsWith("/next/admin")) return AdminApp;
  if (pathname.startsWith("/docs")) return DocsApp;
  return HomeApp;
};


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  