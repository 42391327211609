import { graphql } from "admin/graphql/generated";
import { GetFragmentType, useGraphQLQuery } from "made-this-ui";
import { VariablesOf } from "@graphql-typed-document-node/core";

export const ShortcutsListShortcutFragment = graphql(/* GraphQL */ `
  fragment ShortcutsListShortcut on Shortcut {
    id
    name
    type
  }
`);

const ShortcutsListQuery = graphql(/* GraphQL */ `
  query ShortcutsList($where: ShortcutWhereInput) {
    currentUser {
      shortcuts(where: $where) {
        nodes {
          ...ShortcutsListShortcut
        }
      }
      shortcutsTotal: shortcuts {
        totalCount
      }
    }
  }
`);

export type ShortcutsListShortcut = GetFragmentType<
  typeof ShortcutsListShortcutFragment
>;

type ShortcutWhereInput = VariablesOf<typeof ShortcutsListQuery>;

export const useShortcutsList = (variables?: ShortcutWhereInput) => {
  return useGraphQLQuery(ShortcutsListQuery, {
    variables,
    keepPreviousData: true,
  });
};
