import { useDisclosure, useTheme } from "@chakra-ui/react";
import { useKeyPress } from "admin/lib/useKeyPress";
import { ShortcutsModal, ShortcutsView, ShortcutControl } from ".";
import { ToolButton } from "../ToolButton";

export const Shortcuts = () => {
  const {
    icons: { IconShortcut, IconAdd },
  } = useTheme();

  const {
    isOpen: isNewShortcutOpen,
    onClose: onNewShortcutClose,
    onToggle: onNewShortcutToggle,
  } = useDisclosure();

  const {
    isOpen: isViewShortcutsOpen,
    onToggle: onViewShortcutsOpen,
    onClose: onViewShortcutsClose,
  } = useDisclosure();

  const handleCloseAllPopups = () => {
    onNewShortcutClose();
    onViewShortcutsClose();
  };

  useKeyPress("Escape", handleCloseAllPopups);
  useKeyPress("g", onNewShortcutToggle, true);
  useKeyPress("e", onViewShortcutsOpen, true);

  return (
    <>
      <ToolButton
        icon={IconShortcut}
        label="Shortcuts"
        onClick={onViewShortcutsOpen}
      />
      <ToolButton
        icon={IconAdd}
        label="Create Shortcut"
        onClick={onNewShortcutToggle}
      />

      {isViewShortcutsOpen && (
        <ShortcutsModal onClose={handleCloseAllPopups}>
          <ShortcutsView {...{ onViewShortcutsClose, onNewShortcutToggle }} />
        </ShortcutsModal>
      )}

      {isNewShortcutOpen && (
        <ShortcutsModal onClose={handleCloseAllPopups}>
          <ShortcutControl onComplete={onNewShortcutClose} />
        </ShortcutsModal>
      )}
    </>
  );
};
