import { Stack, Text, useToast } from "@chakra-ui/react";
import {
  FormButtonSubmit,
  FormFieldText,
  useGraphQLMutation,
} from "made-this-ui";
import { FormProvider, useForm } from "react-hook-form";
import { graphql } from "admin/graphql/generated";
import { useQueryClient } from "@tanstack/react-query";
import { useShortcutPathMap } from "./useShortcutPathMap";
import { useAdminTranslation } from "admin/lib/useAdminTranslation";
import { useRouter } from "next/router";
import { useEffect } from "react";

const ShortcutCreateMutation = graphql(/* GraphQL */ `
  mutation ShortcutCreateMutation($attributes: ShortcutAttributesInput!) {
    shortcutCreate(attributes: $attributes) {
      errors
      shortcut {
        id
      }
    }
  }
`);

const useShortcutCreateMutation = () => {
  const qc = useQueryClient();
  return useGraphQLMutation(ShortcutCreateMutation, {
    onSuccess: (data) => {
      if (
        data.shortcutCreate?.errors &&
        data.shortcutCreate?.errors.length > 0
      ) {
        return;
      }
      qc.invalidateQueries();
    },
  });
};

interface FormNewShortcutData {
  name: string;
  note: string;
}

const defaultValues = {
  name: "",
  note: "",
};

export type ShortcutsCreateNewProps = {
  onCreated: () => void;
};

export const ShortcutsCreateNew = ({ onCreated }: ShortcutsCreateNewProps) => {
  const { t } = useAdminTranslation();
  const toast = useToast();
  const { mutateAsync: shortcutCreate } = useShortcutCreateMutation();
  const { pathIdentifier, getState } = useShortcutPathMap();
  const { query, pathname, replace } = useRouter();
  const form = useForm<FormNewShortcutData>({
    defaultValues,
  });
  const { setFocus } = form;

  const { handleSubmit } = form;

  const onSubmit = async (data: FormNewShortcutData) => {
    const res = await shortcutCreate({
      attributes: {
        name: data.name,
        type: pathIdentifier,
        state: getState(),
      },
    });

    const errors = res.shortcutCreate?.errors;
    if (errors && errors.length > 0) {
      console.error(errors);
      toast({
        position: "top",
        status: "error",
        title: t("texts.shortcuts.save_failed_message"),
      });
      return;
    }

    form.reset(defaultValues);
    toast({
      title: t("texts.shortcuts.created"),
      status: "success",
      position: "top",
    });
    onCreated();
    await replace({
      pathname,
      query: {
        ...query,
        sid: res.shortcutCreate?.shortcut?.id,
      },
    });
  };

  useEffect(() => {
    setFocus("name");
  }, [setFocus]);

  return (
    <FormProvider {...form}>
      <Stack as="form" onSubmit={handleSubmit(onSubmit)} spacing={4}>
        <Text as="h1" textStyle="sub-md-strong" color="primary.800">
          {t("texts.shortcuts.new")}
        </Text>
        <FormFieldText
          name="name"
          autoComplete="off"
          placeholder={t("texts.shortcuts.field_name")}
        />

        <FormButtonSubmit>{t("texts.shortcuts.create")}</FormButtonSubmit>
      </Stack>
    </FormProvider>
  );
};
